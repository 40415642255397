header {
  font-size: 2em;
  font-weight: bold;

  box-shadow: -3px -3px 7px #ffffff, 3px 3px 5px #ceced1;

  .title {
    padding: 0.5em 1em;
  }
}

main {
  overflow: auto;
}

#content {
  margin: 2em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1em;
}

.notes {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(100%, 20em), 1fr));
  gap: 1em;
  justify-items: stretch;
}

.ajouter-note {
  color: darkseagreen;
  font-size: 2em;

  cursor: pointer;
  display: flex;
  box-shadow: -3px -3px 7px #ffffff, 3px 3px 5px #ceced1;
  border-radius: 0.12em;
  position: relative;
}

.ajouter-note:active::after {
  border-radius: 0.12em;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: transparent;
  box-shadow: inset -3px -3px 7px #c2c2c2, inset 3px 3px 5px #a9a9a9;
}

.App {
  font-family: Hanken Grotesk, sans-serif;
  display: flex;
  flex-direction: column;

  height: 100dvh;
  width: 100vw;

  overflow: hidden;

  background: #ecf0f3;
}
