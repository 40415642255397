$white: #ffffff;
$lightGray: lightgray;
$gray: gray;
$darkSeaGreen: darkseagreen;
$rgbRed: rgb(216, 121, 121);

.note {
  padding: 1em;
  box-shadow: -3px -3px 7px $white, 3px 3px 5px $lightGray;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  position: relative;
  scroll-margin: 1em;
  border-radius: 0.2em;

  &[data-new-note="true"] {
    animation: wiggle 0.2s ease-in-out;
  }

  &:hover .note-actions,
  &[data-editable="true"] .note-actions {
    visibility: visible;
    opacity: 1;
  }

  .note-title,
  .note-content,
  .note-add-container {
    border-bottom: transparent solid 1px;
  }

  &[data-editable="true"] {
    .note-title,
    .note-content,
    .note-add-container {
      border-bottom: $gray solid 1px;
    }

    .note-title,
    .note-content,
    .note-add-label {
      cursor: text;
    }

    .note-add-label:empty:before {
      content: "label";
      color: $gray;
    }

    .note-label {
      cursor: pointer;

      &:hover .note-label-click-delete {
        opacity: 0.9;
        box-shadow: inset -3px -3px 7px $white, inset 3px 3px 5px $lightGray;
        border-radius: 1.3em;
      }
    }

    .note-content:empty:before {
      content: "Contenu";
      color: $gray;
    }

    .note-title:empty:before {
      content: "Contenu";
      color: $gray;
    }

    .note-actions {
      transform: rotateX(180deg);
      transform-origin: top;
      border-radius: 0 0 1em 1em;
      
      .actions-wrapper {
        transform: rotateX(180deg);
      }
    }
  }

  .note-title {
    font-weight: bold;
  }

  .note-content {
    word-break: break-word;
  }

  .note-labels {
    word-break: break-all;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5em;
  }

  .note-label {
    position: relative;
    padding: 0.3em;
    border-radius: 1.3em;
    border: $lightGray solid 1px;
    box-shadow: -3px -3px 7px $white, 3px 3px 5px $lightGray;
    overflow: hidden;

    .note-label-click-delete {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $rgbRed;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      opacity: 0;

      i {
        color: $white;
      }
    }
  }

  .note-add-container {
    display: flex;
    align-items: center;
    gap: 0.4em;

    .note-add-button {
      color: $gray;
    }
  }

  .note-actions {
    position: absolute;
    top: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    transition: all ease-in-out 0.2s;
    padding: 0.5em;
    border-radius: 0 0 0 1em;
    background-color: #d9d9d9;

    & .actions-wrapper {
      display: flex;
      gap: 0.5em;
    }

    i {
      cursor: pointer;
      font-size: 1.5em;

      &:active {
        filter: brightness(70%);
      }
    }
  }

  .note-edit {
    color: $gray;
  }

  .note-save {
    color: $darkSeaGreen;
  }

  .note-delete {
    color: $rgbRed;
  }

  .note-cancel {
    color: $gray;
  }
}

@keyframes wiggle {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}
